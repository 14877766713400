/**
 * Elements we want to parallax
 */
const elements = [
	'.wp-block-media-text.is-image-fill:not(.no-parallax) .wp-block-media-text__media',
	'.block-page-header img',
	'.slide img:not(.no-parallax)',
	'.slide video',
	'.block-section:not(.no-parallax) .section-bg-image'
];

if (typeof Ukiyo !== 'undefined' && typeof Lenis !== 'undefined') {
	/**
	 * Ukiyo.js
	 *
	 * @link https://github.com/yitengjun/ukiyo-js
	 */
	const parallax = new Ukiyo(
		elements.join(','),
		{
			speed: 0.5,
			externalRAF: true,
		}
	);

	/**
	 * Lenis smooth scroll
	 *
	 * @link https://github.com/darkroomengineering/lenis
	 */
	const lenis = new Lenis({
		duration: 0.75,
		smoothWheel: true,
		smoothTouch: false,
	});

	/**
	 * Animate
	 *
	 * @link https://github.com/yitengjun/ukiyo-js?tab=readme-ov-file#-using-external-requestanimationframe
	 */
	function raf(time) {
		parallax.animate();
		lenis.raf(time);
		requestAnimationFrame(raf);
	}
	requestAnimationFrame(raf);
}
